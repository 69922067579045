<template>
	<!-- 项目-任务-原始任务-->
  <div class="container" style="padding: 20px;">
    <GModule>
		<div class="product" v-if="productArr.length > 0">
			<a-tabs v-model:activeKey="activeKey">
				<a-tab-pane v-for="(i,index) in productArr" :key="index" :tab="i.name"></a-tab-pane>
			</a-tabs>
		</div>
		<div class="time-box" v-if="activeKey == 0">
			<p>任务时间：{{allTime}}</p>
			<p>本期合计工时：</p>
			<p>{{allHour||0}}</p>
		</div>	
		<div style="display: flex;justify-content: space-between;align-items: center;"  v-else>
			<div class="time-box2">
				<p>本期合计工时：</p>
				<p>{{allHour||0}}</p>
				<p>剩余免费工时：</p>
				<p>{{last_free || 0}}</p>
			</div>
			<a-button v-if="authority.commit_hours" :class="isLock ? 'clo6' : 'clo3'" @click="lockThis()">{{isLock?'已锁定':'锁定本期'}}</a-button>
		</div>
		<div class="condition-box">
			<div class="state-box">
				<p>指派给</p>
				<a-select ref="select"
					v-model:value="manIndex"
					style="width: 120px"
					:options="manArr">
				</a-select>
			</div>
			<div class="state-box">
				<p>状态</p>
				<a-select ref="select"
					v-model:value="nstatusIndex"
					style="width: 120px"
					:options="nstatusArr">
				</a-select>
			</div>
			<div class="state-box">
				<p>优先级</p>
				<a-select ref="select"
					v-model:value="levelIndex"
					style="width: 120px"
					:options="levelArr">
				</a-select>
			</div>
			<div class="flx1"></div>
			<template v-if="authority.pr_index && activeKey != 0&&!authority.de_index">
				<a-button class="btn clo1" @click="goToTask()">+建任务</a-button>
			</template>
			<template v-if="productArr&&productArr[activeKey]&&productArr[activeKey].name=='原始需求'">
				<a-button class="btn clo1" v-if="authority.commit_scheduling&&manIndex==-1" @click="commit('scheduling')">提交排期</a-button>
				<a-button class="btn clo1" v-if="authority.fail_scheduling" @click="modalsfail={title:'选择驳回排期的开发',type:'scheduling',mans:'未选择',msg:''}">驳回排期</a-button>
				<a-button class="btn clo1" v-if="authority.commit_hours&&manIndex==-1" @click="commit('hours')">提交工时</a-button>
				<a-button class="btn clo1" v-if="authority.fail_hour" @click="modalsfail={title:'选择驳回工时的开发',type:'hour',mans:'未选择',msg:''}">驳回工时</a-button>
				<a-button class="btn clo1" v-if="authority.task_opt" @click="openModals('static')">批量确认静态</a-button>
				<a-button class="btn clo1" v-if="authority.task_opt" @click="openModals('union')">批量确认联调</a-button>
			</template>
			<template v-else-if="productArr&&productArr[activeKey]">
				<a-button class="btn clo1" v-if="authority.commit_hours" @click="commit('hours')">提交工时</a-button>
				<template v-if="authority.fail_hour">
					<a-button class="btn clo1" @click="goAhead()" v-if="isLock">驳回工时</a-button>
					<a-button class="btn clo1" @click="modalsfail={title:'选择驳回工时的开发',type:'hour',mans:'未选择',msg:''}" v-else>驳回工时</a-button>
				</template>
				<a-button class="btn clo1" v-if="authority.task_opt" @click="openModals('all')">批量确认</a-button>
			</template>

			<a-modal v-if="modalsfail" :visible="!!modalsfail" :title="modalsfail.title" @ok="failTask" @cancel="modalsfail=null">
				<a-form
					class="need-box"
					:label-col="{ span: 7}"
					:wrapper-col="{ span: 15}"
					autocomplete="off">
					<a-form-item label="选择开发：">
						<a-select ref="select"
							v-model:value="modalsfail.mans"
							:options="manArrs">
						</a-select>
					</a-form-item>
					<a-form-item label="驳回理由：">
            <a-textarea :style="{'height': 150 + 'px','resize':'none'}" v-model:value="modalsfail.msg"/>
					</a-form-item>
        </a-form>
			</a-modal>

		</div>
		<a-table 
			class="ttable" 
			:columns="columnsHd" 
			:data-source="data" 
			:scroll="{ x: twidth }" 
			:row-selection="{ columnWidth:25,selectedRowKeys: rowSelection, onChange: onSelectChange }"
			bordered
			:pagination="false">
			<template #bodyCell="{ column ,record,index}">
				<template v-if="column.dataIndex === 'priority_text'">
					<template v-if="authority.edit">
						<a-select
							class="input-select"
							ref="select"
							v-model:value="record.priority"
							@change="priorityChange($event,record)">
							<a-select-option value="urgent">紧急</a-select-option>
							<a-select-option value="commonly">一般</a-select-option>
							<a-select-option value="post">后置</a-select-option>
						</a-select>
					</template>
					<template v-else>
						<p :class="['level-box ptxtp',record.priority=='urgent' ? 'level-red':record.priority == 'commonly' ? 'level-orange':'level-green']">{{record.priority=='urgent'?1:record.priority=='commonly'?2:3}}</p>


						<!-- <a-button size="small" type="danger" v-if="record.priority=='urgent'||record.priority == 'commonly'||record.priority == 'post'" class="btn" :class="record.priority == 'commonly'?'clo2':record.priority == 'post'?'clo1':''">{{record.priority_text}}</a-button>
						<p v-else>未填写</p> -->
					</template>
				</template>
				<template v-if="column.title === '需求描述'">
					<div class="ellipsis-rows" v-text="record.demand_describe"></div>
				</template>
				<template v-if="column.title === '描述补充'">
					<div class="ellipsis-rows" v-text="record.describe"></div>
				</template>
				<template v-if="column.title === '需求图片'">
					<div class="image-w-h" v-if="record.imgArr && record.imgArr.length > 0">
						<a-image :preview="false" :src="record.imgArr[0]" @click="openSwiper(record.imgArr)"/>
					</div>
				</template>
				<template v-if="column.title === '图片补充'">
					<div class="image-w-h" v-if="record.images && record.images.length > 0">
						<a-image :preview="false" :src="record.images[0]" @click="openSwiper(record.images)"/>
					</div>
				</template>
				<template v-if="column.title === '指派给' && isShow1">
					<view class="tpdf">
						<view class="mens">
							<view class="span" v-for="it in recordUsers(record)" v-text="it.nickname"></view>
						</view>
						<form-outlined v-if="authority.edit || this.authority.index" style="fontSize:20px;color:#999999;" @click="openMens(record)"/>
					</view>
				</template>

				<!-- 排期、工时 -->
				<template v-if="column.key === 'staticDay'">
					<template v-if="authority.write_scheduling&&manIndex==-1">
						<a-range-picker 
							v-if="record.has_static == 'yes'" 
							class="days" 
							v-model:value="record.staticDate" 
							:bordered="false" 
							:disabled="record.scheduling_status=='submit'" 
							@change="editTime(record,'static','Date')">
							<template #suffixIcon>
								<calendar-outlined v-if="record.scheduling_status!='wait'" />
							</template>
						</a-range-picker>
						<div class="stop-box" v-else>
							<stop-outlined class="sbox"/>
						</div>
					</template>
					<div class="stop-box" v-else-if="record.has_static == 'no'">
						<stop-outlined class="sbox"/>
					</div>
					<div v-else-if="getTheInfo(record,'static','date')" v-html="getTheInfo(record,'static','date')"></div>
					<div v-else>未填写</div>
				</template>
				<template v-if="column.key === 'staticHour' && isShow">
					<template v-if="authority.write_hours&&manIndex==-1">
						<a-input-number 
							v-if="record.has_static == 'yes'" 
							class="tinp" 
							:controls="false" 
							:bordered="false" 
							v-model:value="record.staticHour" 
							:disabled="record.hour_status=='submit'" 
							@change="editTime(record,'static','Hour')">
						</a-input-number>
						<div class="stop-box" v-else>
							<stop-outlined class="sbox"/>
						</div>
					</template>
					<div class="stop-box" v-else-if="record.has_static == 'no'">
						<stop-outlined class="sbox"/>
					</div>
					<div v-else-if="getTheInfo(record,'static','hour')" v-html="getTheInfo(record,'static','hour')"></div>
					<div v-else>未填写</div>
				</template>
				<template v-if="column.key === 'unionDay'">
					<template v-if="authority.write_scheduling&&manIndex==-1">
						<a-range-picker 
							v-if="record.has_union == 'yes'"  
							class="days" 
							v-model:value="record.unionDate" 
							:bordered="false" 
							:disabled="record.scheduling_status=='submit'" 
							@change="editTime(record,'union','Date')">
							<template #suffixIcon>
								<calendar-outlined v-if="record.scheduling_status!='wait'" /><!-- 时间图标 -->
							</template>
						</a-range-picker>
						<div class="stop-box" v-else>
							<stop-outlined class="sbox"/>
						</div>
					</template>
					<div class="stop-box" v-else-if="record.has_union == 'no'">
						<stop-outlined class="sbox"/>
					</div>
					<div v-else-if="getTheInfo(record,'union','date')" v-html="getTheInfo(record,'union','date')"></div>
					<div v-else>未填写</div>
				</template>
				<template v-if="column.key === 'unionHour' && isShow">
					<template v-if="authority.write_hours&&manIndex==-1">
						<a-input-number 
							v-if="record.has_union == 'yes'"   
							class="tinp" 
							:controls="false" 
							:bordered="false" 
							v-model:value="record.unionHour" 
							:disabled="record.hour_status=='submit'" 
							@change="editTime(record,'union','Hour')">
						</a-input-number>
						<div class="stop-box" v-else>
							<stop-outlined class="sbox"/>
						</div>	
					</template>
					<div class="stop-box" v-else-if="record.has_union == 'no'">
						<stop-outlined class="sbox"/>
					</div>
					<div v-else-if="getTheInfo(record,'union','hour')" v-html="getTheInfo(record,'union','hour')"></div>
					<div v-else>未填写<!-- {{record.unioninfo.hour}} --></div>
				</template>
				<template v-if="column.key === 'allHour'">
					<template v-if="authority.write_hours&&manIndex==-1">
						<a-input-number class="tinp" :controls="false" :bordered="false" v-model:value="record.allHour" :disabled="record.hour_status=='submit'" @change="editTime(record,'all','Hour')"></a-input-number>
					</template>
					<div v-else-if="getTheInfo(record,'all','hour')" v-html="getTheInfo(record,'all','hour')"></div>
					<div v-else>未填写</div>
				</template>
				<!-- 排期、工时 -->


				<template v-if="column.key === 'unionStatus'">
					<div class="stop-box" v-if="record.has_union == 'no'">
						<stop-outlined class="sbox"/>
					</div>
					<div v-else-if="getTheInfo(record,'union','status')" v-html="getTheInfo(record,'union','status')"></div>
					<template v-else>未填写
						<!-- <span :style="record.unioninfo.date_status == 'fail' || record.unioninfo.date_status == 'overdue' ? 'color:red;' : ''">{{record.unioninfo.date_status_text}}</span> -->
					</template>
				</template>
				<template v-if="column.key === 'staticStatus'">
					<div class="stop-box" v-if="record.has_static == 'no'">
						<stop-outlined class="sbox"/>
					</div>
					<!-- <template v-else-if="!record.staticinfo || !record.staticinfo.date_status_text">未填写</template>
					<template v-else>
						<span :style="record.staticinfo.date_status == 'fail' || record.staticinfo.date_status == 'overdue' ? 'color:red;' : ''">{{record.staticinfo.date_status_text}}</span>
					</template> -->
					<div v-else-if="getTheInfo(record,'static','status')" v-html="getTheInfo(record,'static','status')"></div>
					<template v-else>未填写</template>
				</template>
				<template v-if="column.key === 'allStatus'">
					<div v-if="getTheInfo(record,'all','status')" v-html="getTheInfo(record,'all','status')"></div>
					<template v-else>未填写</template>
					<!-- <template v-if="!record.allinfo || !record.allinfo.date_status_text">未填写</template>
					<template v-else>{{record.allinfo.date_status_text}}</template> -->
				</template>
		    <template v-if="column.key === 'operation'">
					<div class="caozuo">
						<template v-if="productArr&&productArr[activeKey].name=='原始需求'">
							<template v-if="manIndex==-1&&authority.complete">
								<!-- <a-popconfirm
									title="是否确认静态已完成?"
									ok-text="确定"
									cancel-text="取消"
									@confirm="editType(record.id,'complete','static')">
									<p>静态完成</p>
								</a-popconfirm> -->
								<p v-if="record.staticinfo&&(record.staticinfo.date_status=='incomplete'||record.staticinfo.date_status=='fail'||record.staticinfo.date_status=='overdue')" @click="modals={ids:record.id,title:'静态',type:'complete',typ:'static',list:[]}">静态完成</p>
								<p v-else style="color:#999">静态完成</p>
								<!-- <a-popconfirm
									v-if="record.unioninfo&&(record.unioninfo.date_status=='incomplete'||record.unioninfo.date_status=='fail')"
									title="是否确认联调已完成?"
									ok-text="确定"
									cancel-text="取消"
									@confirm="editType(record.id,'complete','union')">
									<p>联调完成</p>
								</a-popconfirm> -->
								<p v-if="record.unioninfo&&(record.unioninfo.date_status=='incomplete'||record.unioninfo.date_status=='fail'||record.unioninfo.date_status=='overdue')" @click="modals={ids:record.id,title:'联调',type:'complete',typ:'union',list:[]}">联调完成</p>
								<p v-else style="color:#999">联调完成</p>
							</template>



							<template v-if="authority.task_opt">
								<template v-if="record.staticinfo&&record.staticinfo.date_status=='wait'">
									<!-- <a-popconfirm
										title="是否确认静态完成?"
										ok-text="确定"
										cancel-text="取消"
										@confirm="editType(record.id,'confirm','static')">
										<p>确认静态</p>
									</a-popconfirm>
									<a-popconfirm
										title="是否驳回静态完成?"
										ok-text="确定"
										cancel-text="取消"
										@confirm="editType(record.id,'fail','static')">
										<p>驳回静态</p>
									</a-popconfirm> -->
									<p @click="editType(record.id,'confirm','static')">确认静态</p>
									<p @click="editType(record.id,'fail','static')">驳回静态</p>
								</template>
								<template v-else>
									<p style="color:#999">确认静态</p>
									<p style="color:#999">驳回静态</p>
								</template>

								<template v-if="record.unioninfo&&record.unioninfo.date_status=='wait'">
									<!-- <a-popconfirm
										title="是否确认联调完成?"
										ok-text="确定"
										cancel-text="取消"
										@confirm="editType(record.id,'confirm','union')">
										<p>确认联调</p>
									</a-popconfirm>
									<a-popconfirm
										title="是否驳回联调完成?"
										ok-text="确定"
										cancel-text="取消"
										@confirm="editType(record.id,'fail','union')">
										<p>驳回联调</p>
									</a-popconfirm> -->
									<p @click="editType(record.id,'confirm','union')">确认联调</p>
									<p @click="editType(record.id,'fail','union')">驳回联调</p>
								</template>
								<template v-else>
									<p style="color:#999">确认联调</p>
									<p style="color:#999">驳回联调</p>
								</template>
							</template>
						</template>
						<template v-else-if="productArr">
							<template v-if="manIndex==-1&&authority.complete">
								<!-- <a-popconfirm
									v-if="record.allinfo&&(record.allinfo.date_status=='incomplete'||record.allinfo.date_status=='fail')"
									title="是否确认已完成?"
									ok-text="确定"
									cancel-text="取消"
									@confirm="editType(record.id,'complete','all')">
									<p>完成2</p>
								</a-popconfirm> -->
								<p v-if="record.allinfo&&(record.allinfo.date_status=='incomplete'||record.allinfo.date_status=='fail'||record.allinfo.date_status=='overdue')" @click="modals={ids:record.id,title:'',type:'complete',typ:'all',list:[]}">完成</p>
								<p v-else style="color:#999">完成</p>
							</template>


							<template v-if="authority.task_opt">
								<template v-if="record.allinfo&&record.allinfo.date_status=='wait'">
									<!-- <a-popconfirm
										title="是否确认完成?"
										ok-text="确定"
										cancel-text="取消"
										@confirm="editType(record.id,'confirm','all')">
										<p>确认</p>
									</a-popconfirm>
									<a-popconfirm
										title="是否驳回完成?"
										ok-text="确定"
										cancel-text="取消"
										@confirm="editType(record.id,'fail','all')">
										<p>驳回</p>
									</a-popconfirm> -->
									<p @click="editType(record.id,'confirm','all',record)">确认</p>
									<p @click="editType(record.id,'fail','all')">驳回</p>
								</template>
								<template v-else>
									<p style="color:#999">确认</p>
									<p style="color:#999">驳回</p>
								</template>


							</template>
						</template>

						<p v-if="authority.edit" @click="updateBtn(record.id,{value:record.describe,images:record.imgArr},index)">修改</p>
						<template v-if="authority.close">
							<!-- <a-popconfirm
								v-if="record.status!='close'"
								title="是否确认关闭此任务?"
								ok-text="确定"
								cancel-text="取消"
								@confirm="closeType(record.id)">
								<p>关闭</p>
							</a-popconfirm>
							<a-popconfirm
								v-else
								title="是否确认恢复此任务?"
								ok-text="确定"
								cancel-text="取消"
								@confirm="openType(record.id)">
								<p>恢复</p>
							</a-popconfirm> -->
							<p style="color: #999;" v-if="record.status == 'close'">关闭</p>
							<p @click="closeType(record.id)" v-else>关闭</p>
							<p @click="openType(record.id)" v-if="record.status == 'close'">恢复</p>
							<p style="color: #999;" v-else>恢复</p>
						</template>


						<router-link v-if="authority.getRow" :to="'/taskProjectDetail?id='+record.id+(manIndex==-1?'&me=1':'')"><p>详情</p></router-link>
						<!-- <p>详情</p> -->
					</div>
				</template>
		  </template>
		</a-table>

				<a-modal v-if="openMans.on" v-model:visible="openMans.on" title="指派给" @ok="openCheck()" @cancel="openMans.on=!1;openMans.list=[];openMans.id=0;">
					<div v-for="(ite,ix) in openMans.list">
						<a-checkbox
							:key="ix"
							:checked="ite.checked" 
							@click="ite.checked=!ite.checked">
							{{ite.label}}
							<template v-if="activeKey == 0&&ite.checked">
								<a-checkbox :checked="ite.has_static == 'yes' ? true : false" @click="ite.has_static=='yes'?ite.has_static='no':ite.has_static='yes'">静态</a-checkbox>
								<a-checkbox :checked="ite.has_union == 'yes' ? true : false" @click="ite.has_union=='yes'?ite.has_union='no':ite.has_union='yes'">联调</a-checkbox>
							</template>
						</a-checkbox>
					</div>
				</a-modal>

		<!-- 图片上传 -->
		<a-modal v-if="modals&&modals.list" :maskClosable="!1" v-model:visible="omodals" :title="'确认'+modals.title+'完成'" @ok="editType(modals.ids,modals.type,modals.typ,modals.list)" @cancel="modals=null">
			<a-upload
				name="file"
				v-model:file-list="modals.list"
				:maxCount="8"
				list-type="picture-card"
				:customRequest ="customRequest"
				@remove="remove">
				<div class="addimg" v-if="modals.list.length < 8">
					<plus-outlined />
					<p style="color: #999999;">点击上传图片</p>
				</div>
			</a-upload>
		</a-modal>
		<a-modal v-else-if="modals" v-model:visible="omodals" :title="modals.title" @ok="editType(modals.ids,modals.type,modals.typ)" @cancel="closeBtn()">
			<div v-text="modals.name"></div>
		</a-modal>
		<div class="documentFt">
		  <a-pagination v-model:current="page" size="small" v-model:pageSize="limit" :total="total" />
		</div>
    </GModule>
  </div>
</template>
<script>
import dayjs from 'dayjs';
export default {
  name: 'TaskProject',
  data(){
    return {
    	openMans:{
    		on:!1,
    		list:[],
    		id:0
    	},
    	total:0,
    	twidth:2300,
	    productArr:[], // 需求tab标签页
			activeKey:0,
			stateIndex:0,
			manArr:[{label:'我的',value:-1},{label:'全部',value:0}],// 状态列表
			manIndex:-2,
			nstatusArr:[],
			nstatusIndex:'all',
			levelArr:[] ,// 优先级列表
			levelIndex:'all',
			data: [],
			rowSelection:[],
			modals:null,
			modalsfail:null,
			updateIndex:0, // 修改的下标
			page:1,
			limit:10,
			allTime:'',
			last_free:0,
			allHour:0,
			isShow:true,
			isShow1:true,
			isFirst:true ,// 是否显示指派给
			isLock:false ,// 锁定
			manArrsd:[],
			amans:[]
    }
  },
	computed: {
		getTheInfo(){
			return function(item,key,type) {
				let info = item[key+'info'],
					txtArr=[];
				// return JSON.stringify(item)
				if(info&&info.length){
					info.forEach(it=>{
						if(type=='hour'&&it.hour){
							txtArr.push('<div>'+it.hour+'</div>');// +it.user_info.nickname+': '
						}else if(type=='date'&&it.begin_date&&it.end_date){
							txtArr.push('<div>'+it.begin_date+'→'+it.end_date+'</div>');
						}else if(type=='status'){
							txtArr.push('<div style="'+(it.date_status == 'fail' || it.date_status == 'overdue' ? 'color:red;' : '')+'">'+it.date_status_text+'</div>');
						}
					})
				}else if(info){
						if(type=='hour'&&info.hour){
							txtArr.push('<div>'+it.hour+'</div>');// +it.user_info.nickname+': '
						}else if(type=='date'&&info.begin_date&&info.end_date){
							txtArr.push('<div>'+info.begin_date+'→'+info.end_date+'</div>');
						}else if(type=='status'){
							txtArr.push('<div style="'+(info.date_status == 'fail' || info.date_status == 'overdue' ? 'color:red;' : '')+'">'+info.date_status_text+'</div>');
						}
				}
				return txtArr.join('')
			}
		},
		manArrs(){
			var arr = [].concat(this.manArr);
			arr.shift()
			return arr
		},
		isChecks(){
			return function(id) {
				return this.amans.findIndex(it=>it.id==id)>=0?(!1):(!0)
			}
		},
		omodals(){
			return !!this.modals
		},
		proId(){
			return this.$store.state.proId
		},
		mId(){
			var id = 0;
			if(this.$store.state.selectedMemu&&this.$store.state.selectedMemu.length)id=this.$store.state.selectedMemu[this.$store.state.selectedMemu.length - 1].ids;
			return id
		},
		recordUsers(){
			return function(item) {
				let users = [];
				if(item.allinfo.length){
					users = item.allinfo.map(it=>{
						return it.user_info;
					});
					users = users.filter((it,idx)=>idx<2);
				}else{
					let u1 = item.unioninfo.map(it=>{
						return it.user_info;
					});
					let u2 = item.staticinfo.map(it=>{
						return it.user_info;
					});
					// u2.filter(it=>!u1.find(ito=>ito.id==it.id));
					users = u1.concat(u2);
					users = users.filter((it,idx)=>idx<2);
				}
				return JSON.parse(JSON.stringify(users))
			}
		},
		columnsHd() {
			var arr = [{
				    title: '任务ID',
				    width: 40,
				    dataIndex: 'id',
				    key: 'id',
				    fixed: 'left',
				  },{
					title: '优先级',
					width: 40,
					dataIndex: 'priority_text'
				// }, {
				//     title: '任务描述',
				//     width: 150,
				//     dataIndex: 'describe',
				//     key: 'describe',
				// 	ellipsis:true
				  },  {
				    title: '需求描述',
				    width: 150,
				    dataIndex: 'demand_describe',
				    key: 'demand_describe',
				  }, {
				    title: '描述补充',
				    width: 150,
				    dataIndex: 'describe',
				    key: 'describe',
				  }, {
				    title: '需求图片',
				    width: 120,
				    dataIndex: 'demand_image',
				    key: 'demand_image'
				  }, {
				    title: '图片补充',
				    width: 120,
				    dataIndex: 'image',
				    key: 'image'
				  }, {
				    title: '指派给',
				    width: 100,
				    dataIndex: 'user_nickname',
				    key: 'user_nickname'
				  }];
				if(this.productArr&&this.productArr[this.activeKey]&&this.productArr[this.activeKey].name=='原始需求'){
					arr = arr.concat([{
				    title: '静态页面排期',
				    width: 110,
				    'min-width': 300,
				    dataIndex: 'staticDay',
				    key: 'staticDay',
				  }, {
				    title: '页面工时（时）',
				    width: 60,
				    dataIndex: 'staticHour',
				    key: 'staticHour'
				  },{
				    title: '静态状态',
				    width: 60,
				    dataIndex: 'staticStatus',
				    key: 'staticStatus'
				  }, {
				    title: '接口联调/开发排期',
				    width: 110,
				    'min-width': 300,
				    dataIndex: 'unionDay',
				    key: 'unionDay',
				  }, {
				    title: '接口工时（时）',
				    width: 60,
				    dataIndex: 'unionHour',
				    key: 'unionHour'
				  },{
				    title: '联调状态',
				    width: 60,
				    dataIndex: 'unionStatus',
				    key: 'unionStatus'
					}])
					this.twidth = 2900;
				}else{
					arr = arr.concat([{
				    title: '状态',
				    width: 60,
				    dataIndex: 'allStatus',
				    key: 'allStatus'
				  }, {
				    title: '工时',
				    width: 60,
				    dataIndex: 'allHour',
				    key: 'allHour'
					}])
					this.twidth = 2300;
				}

				arr = arr.concat([{
					title: '操作',
					key: 'operation',
					fixed: 'right',
					width: 120,
				}])
				
				if(!this.isFirst){
					// 开发任务页面 移除指派给
					for(let i in arr){
						if(arr[i].title == '指派给'){
							arr.splice(i,1)
						}
					}
				}
			return arr
		},
    authority(){
      var data = {};
      this.$store.state.params.forEach(item=>{
        data[item.name.split('/')[2]] = true;
      })
      return data
    },
    navchage(){
	    return [this.proId,this.$store.state.subNavIndex,this.$store.state.mainNavIndex]
    },
    navchages(){
    	return [this.nstatusIndex,this.levelIndex,this.mId,this.manIndex,this.activeKey,this.productArr.length]
    },
	isUpdateFresh(){
		return this.$store.state.isUpdateFresh
	},
	updateDescribe(){
		return this.$store.state.updateDescribe
	},
  },
  watch:{
    navchage(){
    	if(this.$store.state.mainNavIndex>=0&&this.$store.state.subNavIndex>=0){
				this.isFirst = true // 菜单切换项目经理时 显示指派给
				this.onLoadx()
    	}
    },
    navchages(){
		  this.page = 1;
		  this.getList()
    },
		isUpdateFresh(val,oldvalue){
			// 修改完成后回显列表
			if(val){
				// 回显任务描述
				this.data[this.updateIndex].describe = this.updateDescribe.value
				this.$store.commit('setUpdateId',null) // 重置Id
				this.$store.commit('setUpdateDescribe',"") //重置需求描述
				this.$store.commit('setIsTaskUpdate',0) // 任务修改标识
				this.$store.commit('setIsUpdateFresh',0) 
			}
		},
		page(){
		  this.getList()
		},
		activeKey(val,old){
			// 切换需求日期时
			if(this.activeKey != 0 && this.productArr[this.activeKey].status == 'lock'){
				this.isLock = true  // 锁定本期后不可操作其他的功能
			}else{
				this.isLock = false 
			}
			this.changeColumn(1,val)
		},
		manIndex(val,old){
			// 切换指派给时
			this.changeColumn(0,val)
		}
  },
	created(){
		this.onLoadx()
	},
  methods:{
  	openMens(it){
  		let newList = this.recordUsers(it);
  		console.log('999 ===> ', this.activeKey, this.productArr)
			this.openMans.list = this.manArrsd.map(ite=>{
				return {
					...ite,
					checked:!!newList.find(i=>i.id==ite.value)
				}
			});
			this.openMans.on = !0;
			this.openMans.id = it.id;
  		// if(this.activeKey){
  		// }else{

  		// }
  	},
  	async openCheck(){
  		var users = this.openMans.list.filter(it=>{
  			return it.checked;
  		});
  		if(this.activeKey == 0){
  			let newl = [];
	  		users.forEach(it=>{
						if(it.has_static=='yes'){
							newl.push({
								user_id:it.value,
								date_type:'static'
							})
						}
						if(it.has_union=='yes'){
							newl.push({
								user_id:it.value,
								date_type:'union'
							})
						}
	  		})
	  		 users = newl;
  		}else{
	  		users = users.map(it=>{
	  			return {
	  				user_id: it.value,
						date_type: 'all'
	  			}
	  		})
  		}
			let res = await this.$utils.api.post({
					url:'/task/edit',
					data:{
						ids:this.openMans.id,
						'row':{
							'data':users
						}
					},
					result:1
				})
			if(res.code==1){
				this.openMans = {
					on:!1,
					list:[],
					id:0
				};
				this.getList();
			}
  	},
		changeClick(item){
			let jde= this.amans.findIndex(it=>it.id==item.value),
				newList = JSON.parse(JSON.stringify(this.amans));
			if(jde>=0){
				newList.splice(jde, 1);
				this.amans = [].concat(newList);
				console.log('this.amans ===>', this.amans)
			}else{
				this.amans.push({
					id:item.value,
					nickname:item.label
				})
			}
		},
  	async priorityChange(el,obj){
  		var res = await this.$utils.api.post({
				url:'/task/edit',
				data:{
					ids:obj.id,
					row:{priority:el}
				},
				result:1
			})
			if(res.code==1){
	  		console.log(res,el,obj.id,obj.data,obj.describe)
	  		this.getList();
			}
  	},
	  changeColumn(type,val){
		  let value,key
		  if(type == 1){
			  value = this.manIndex // 指派给列表-下标
			  key = val // 需求日期-下标
		  }else{
			  value = val // 指派给列表-下标
			  key = this.activeKey // 需求日期-下标
		  }
		  if(this.authority.de_index){
		  	// 开发 - 任务  控制开发任务页面表格列的显示隐藏
		  	for(let i in this.columnsHd){
		  		  if(value == 0 && key == 0){
		  			// 全部 - 原始需求
		  			if(this.columnsHd[i].key == 'staticHour' || this.columnsHd[i].key == 'unionHour'){
		  				// 隐藏工时
		  				this.columnsHd[i].width = 0
		  				this.columnsHd[i].title = ''
						this.isShow = false
		  			}
					this.isFirst = true // 下拉框指派给选中全部后，显示表格上列的指派给
					if(this.columnsHd[i].key == 'user_nickname'){
						// 显示指派给
						this.columnsHd[i].width = 60
						this.columnsHd[i].title = '指派给'
						this.isShow1 = true
					}
		  		  }
		  		  if(value == 0 && key == 1){
		  		  	// 全部 - 新增变更需求
		  			if(this.columnsHd[i].key == 'allHour'){
		  				// 隐藏工时
		  				this.columnsHd[i].width = 0
		  				this.columnsHd[i].title = ''
		  			}
					this.isFirst = true // 下拉框指派给选中全部后，显示表格上列的指派给
		  			if(this.columnsHd[i].key == 'user_nickname'){
		  				// 显示指派给
		  				this.columnsHd[i].width = 60
		  				this.columnsHd[i].title = '指派给'
						this.isShow1 = true
		  			}
		  		  }
		  		  if(value == -1 && key == 0){
		  			// 我的 - 原始需求
		  			if(this.columnsHd[i].key == 'staticHour'){
		  				// 显示页面工时
		  				this.columnsHd[i].width = 60
		  				this.columnsHd[i].title = '页面工时（时）'
						this.isShow = true
		  			}
		  			if(this.columnsHd[i].key == 'unionHour'){
		  				// 显示接口工时
		  				this.columnsHd[i].width = 60
		  				this.columnsHd[i].title = '接口工时（时）'
						this.isShow = true
		  			}
					if(this.columnsHd[i].key == 'user_nickname'){
						// 隐藏指派给
						this.columnsHd[i].width = 0
						this.columnsHd[i].title = ''
						this.isShow1 = false
					}
		  		  }
		  		  if(value == -1 && key == 1){
		  		  	// 我的 - 新增变更需求
		  			if(this.columnsHd[i].key == 'allHour'){
		  				// 显示工时
		  				this.columnsHd[i].width = 60
		  				this.columnsHd[i].title = '工时'
		  			}
		  			if(this.columnsHd[i].key == 'user_nickname'){
		  				// 隐藏指派给
		  				this.columnsHd[i].width = 0
		  				this.columnsHd[i].title = ''
						this.isShow1 = false
		  			}
		  		  }
		  	}
		  }
	  },
  	async failTask(){
			if(this.modalsfail.mans=='未选择'){
				return this.$utils.msgErr('选择驳回的开发')
			}
			if(!this.modalsfail.msg){
				return this.$utils.msgErr('输入驳回理由')
			}
  		var res = await this.$utils.api.post({
				url:'/task/fail_'+this.modalsfail.type,
				data:{user_id:this.modalsfail.mans,msg:this.modalsfail.msg,date_id:this.productArr[this.activeKey].id},
				result:1
			})
			if(res.code==1){
        this.$utils.msgSuc('已驳回');
        this.modalsfail = null
        this.getList();
			}
  	},
  	async closeType(id){
  		var res = await this.$utils.api.post({
				url:'/task/close',
				data:{ids:id},
				result:1
			})
			if(res.code==1){
        this.$utils.msgSuc('已关闭');
        this.getList();
			}
  	},
  	openModals(name){
  		if(!this.rowSelection.length)return this.$utils.msgErr('没有选中任务');
			var type = {
				static:'静态',
				union:'联调',
				all:'',
			};
			var ids = [].concat(this.rowSelection) + '';
			this.modals = {
				title:'确认'+type[name]+'？',
				name:'是否确认ID为 '+ids+'的任务'+type[name]+'完成？',
				typ:name,
				type:'confirm',
				ids:ids
			};
  	},
    remove(e){
      this.modals.list = this.modals.list.filter((item) => {
        return item != e.thumbUrl
      })
    },
    async customRequest (e) {
      // 执行图片上传
      let that = this
      let res = await this.$utils.api.upLoad(e)
      if(res){
        setTimeout(()=>{
          that.$utils.msgSuc('上传成功')
          // 上传成功修改文件状态
          that.modals.list[that.modals.list.length-1].status = 'done' 
          // 图片封面预览
          that.modals.list[that.modals.list.length-1].url = res.data.fullurl
        },500)
      }
    },
  	async editType(ids,type,typ,item){
  		var url = '/task/task_opt',
	  		data = {
	  			ids:ids,
	  			type:typ
	  		};
  		if(type=='complete'){
  			var item = this.data.find(it=>it.id==ids)
		  	if(typ=='all'){
		  		data.ids = item.allinfo.id;
				}else if(typ=='static'){
		  		data.ids = item.staticinfo.id;
				}else if(typ=='union'){
		  		data.ids = item.unioninfo.id;
				}

  			url = '/task/complete';
			if(this.modals){
				data.images = this.modals.list.map(item=>item.url);
			}else{
				data.images = []
			}
  			
  		}else{
  			data.opt = type;
  		}
  		var res = await this.$utils.api.post({
				url:url,
				data:{...data},
				result:1
			})
			if(res.code==1){
				if(type=='complete' || type=='confirm'){
					this.closeBtn()
				}
				this.getList();
				this.$utils.msgSuc('提交成功！');
			}
  	},
  	async commit(name){
  		if(name=='hours'&&this.data&&this.data[0].hour_status=='submit'){
  			this.$utils.msgErr('工时已提交过了，不可重复提交！');
  			return 
  		}else if(name=='scheduling'&&this.data&&this.data[0].scheduling_status=='submit'){
  			this.$utils.msgErr('排期已提交过了，不可重复提交！');
  			return 
  		}
			var res = await this.$utils.api.post({
				url:'/task/commit_'+name,
				data:{date_id:this.productArr[this.activeKey].id},
				result:1
			})
			if(res.code==1)this.getList(),this.$utils.msgSuc('提交成功！')
  	},
		onSelectChange(arr){
			this.rowSelection = arr;
		},
  	async editTime(item,typ,type){
  		var url = type=='Hour'?'/task/write_hours':'/task/write_scheduling',
	  		data = {
	  			ids:null,
	  			type:typ
	  		};
	  	if(typ=='all'){
	  		data.ids = item.allinfo.id;
			}else if(typ=='static'){
	  		data.ids = item.staticinfo.id;
			}else if(typ=='union'){
	  		data.ids = item.unioninfo.id;
			}

			if(!data.ids)return this.$utils.pop.msgErr('数据有误！')
	  	if(type=='Hour'){
	  		if(!item[typ+type]){
	  			this.$utils.pop.msgErr('请输入工时')
	  			return
	  		}
	  		data.hour = item[typ+type];
	  	}else{
	  		if(item[typ+type].length==0){
	  			this.$utils.pop.msgErr('请选择排期')
	  			return
	  		}
	  		let sd = item[typ+type][0],
		  		ed = item[typ+type][1];
	  		data.begin_date = sd.$y+'-'+(sd.$M+1)+'-'+sd.$D;
	  		data.end_date = ed.$y+'-'+(ed.$M+1)+'-'+ed.$D;
	  	}
			var res = await this.$utils.api.post({
				url:url,
				data:data,
				// result:1
			})
			// if(res.code==1)this.getList(),this.$utils.msgSuc('保存成功！')

  	},
  	async onLoadx(){
		  this.page = 1
			this.getTimeList()
			//请求配置项
			if(!this.proId)return;
			var res = await this.$utils.api.post({
				url:'/task/setting',
				data:{},
				result:1
			})
			if(res.code==1){
				var arr = [{
					label:'全部',
					value:'all'
				}];
				for(var i in res.data.mixStatusList){
					arr.push({
						label:res.data.mixStatusList[i],
						value:i
					})
				}
				this.nstatusArr = arr;
				
				var arr1 = [{
					label:'全部',
					value:'all'
				}];
				for(var j in res.data.priorityList){
					arr1.push({
						label:res.data.priorityList[j],
						value:j
					})
				}
				this.levelArr = arr1;
			}

			if(!this.authority.commit_hours){
				var ress = await this.$utils.api.post({
					url:'/team/team_selectpage',
					data:{
						project_id:this.proId,
						label:'develop'
					},
					result:1
				})
				if(res.code==1){
					var arrr = [{label:'全部',value:0}].concat(ress.data.list.map(ite=>{
							return { label:ite.nickname, value:ite.id }
						}));
					this.manArrsd = ress.data.list.map(ite=>{
						return { label:ite.nickname, value:ite.id }
					});
					this.manArr = arrr;
					this.manIndex = 0;
				}
			}else if(this.manIndex!=-1){
				this.manArr = [{label:'我的',value:-1},{label:'全部',value:0}];
				// 首次初始化开发-任务页面时，指派给下拉框默认选中的是我的，
				this.manIndex = -1;
				this.isFirst = false // 控制列表首次不显示指派给
				this.changeColumn(0,this.manIndex) 
			}
			this.getList()
		},
		async getTimeList(){
			if(!this.proId)return;
				// 获取需求日期列表
				let res = await this.$utils.api.post({
					url:"/demand_date/index",
					data:{
						sort:'id',
						order:'asc'
					},
			  	result:1
			  })
			  if(res.code == 1){
			  	this.productArr = res.data.rows
				if(this.productArr.length > 0){
					this.activeKey = this.productArr.length - 1
				}
		  }
		},
	  async getList(){
		  if(!this.proId)return;
	  	// 请求列表
	  	this.data = [];
	  	let info = {
	  		limit: this.limit,
	  		page: this.page,
				op: {},
				filter: {},
				mix_status:this.nstatusIndex&&this.nstatusIndex!='all'?this.nstatusIndex:undefined
	  	}
			if(this.mId){
				// 模块id
				info.op['modular_id'] = 'IN'
				info.filter['modular_id'] = this.mId
			}
			if(this.productArr&&this.productArr.length){
				// 需求日期id
				info.op['date_id'] = 'IN';
				info.filter['date_id'] = this.productArr[this.activeKey].id
			}else{
				return
			}
			if(this.manIndex > 0){
				// 指派给
				info.op['user_id'] = 'IN'
				info.filter['user_id'] = this.manIndex
			}else{
				// 解决开发任务列表指派人下拉全部，会显示项目经理已关闭的任务问题
				if(this.authority.de_index){
					info.is_dev = 1
				}
			}

			if(this.levelIndex != 'all'){
				info.op['priority'] = 'IN'
				info.filter['priority'] = this.levelIndex
			}
			// if(this.nstatusIndex != 'all'){
			// 	// 状态
			// 	info.op['mix_status'] = 'IN'
			// 	info.filter['mix_status'] = this.nstatusIndex
			// }

			if(this.manIndex==-2||!this.productArr.length)return
	  	let res = await this.$utils.api.post({
	  		url: this.manIndex==-1?'/task/de_index':'/task/pr_index',
	  		data: info,
	  		result:1
	  	})
	  	if(res&&res.code == 1){
	  		if(res.data.rows.length==0&&this.page>1){
	  			this.page--
	  			this.getList()
	  			return
	  		}
				// for(let i in res.data.rows){
				// 	res.data.rows[i].imgArr = res.data.rows[i].images.split(',')
				// }
				var dab = {
					static:['Date','Hour'],
					union:['Date','Hour'],
					all:['Hour']
				}
				this.data = res.data.rows.map((item,idx) => {
					item.index = idx;
					item.imgArr =item.demand.images.length > 0 ? item.demand.images.split(',') : [];
					if(this.manIndex==-1){
						item.allinfo = item.allinfo.find(it=>it.user_info.id==this.$store.state.user.id)||null;
						item.unioninfo = item.unioninfo.find(it=>it.user_info.id==this.$store.state.user.id)||null;
						item.staticinfo = item.staticinfo.find(it=>it.user_info.id==this.$store.state.user.id)||null;
					}
					// find(it=>it.user_info.id==this.$store.state.user.id).
					for(var i in dab){
						let dt = item[i+'info'] || {}
						for(var j in dab[i]){
							if(dab[i][j]=='Hour'){
								item[i+dab[i][j]] = dt.hour || '';
							}else if(dt.begin_date&&dt.end_date){
								item[i+dab[i][j]] = [dayjs(dt.begin_date),dayjs(dt.end_date)];
							}else{
								item[i+dab[i][j]] = []
							}
						}
					}
					for(var i in item.demand){
						item['demand_'+i] = item.demand[i];
					}
					for(var i in item.user){
						item['user_'+i] = item.user[i];
					}
					item.key = item.id;
					return item
				})
				if(res.data.extend && res.data.extend.begin_date != null){
					this.allTime = 	res.data.extend.begin_date + '至' + res.data.extend.end_date
				}else{
					this.allTime = 	''
				}
	  		this.allHour = res.data.extend.total_hour;
			this.last_free = res.data.extend.last_free
	  		this.total = res.data.total;
	  	}
	  },
	  openSwiper(arr){
			// 弹出图片列表
			this.$store.commit('setSwiperArr',arr)
			this.$store.commit('setSwiper',1)
	  },
	  updateBtn(id,describe,index){
	  	// 编辑
		this.updateIndex = index
		console.log('回显参数', describe)
	  	this.$store.commit('setIsRecordAdd',1) //弹出修改
	  	this.$store.commit('setUpdateId',id) // 修改Id
	  	this.$store.commit('setUpdateDescribe', describe) //回显
	  	this.$store.commit('setIsTaskUpdate',1) //任务
	  },
	  async openType(id){
		  // 恢复
		  let res = await this.$utils.api.post({
			url:"/task/open",
			data:{
				ids:id
			},
		  	result:1
		  })
		  if(res.code==1){
			  this.$utils.msgSuc('已恢复');
			  this.getList();
		  }
	  },
	  closeBtn(){
		  // 取消
		  this.modals = null
		  this.rowSelection=[]
	  },
	  goAhead(){
		  if(this.isLock){
		  	this.$utils.msgSuc('已锁定')
		  	return
		  }
	  },
	  goToTask(){
		  // 跳转建任务页面
		  if(this.isLock){
		  	this.$utils.msgSuc('已锁定')
		  	return
		  }
		  // this.$router.push({path:'/TaskCreate',query:{isRecord:1}})
		  this.$router.push({path:'/TaskCreate'})
	  },
	  async lockThis(){
		  // 锁定本期
		  if(this.productArr.length > 0 && !this.isLock){
			  let id = this.productArr[this.activeKey].id
			  let res = await this.$utils.api.get({
					url:"/demand_date/locking",
					data:{
						ids:id
					},
			  	result:1
			  })
			  if(res.code==1){
				  this.$utils.msgSuc('已锁定本期');
				  this.getList();
			  }
		  }
	  }
  }

}
</script>

<style scoped lang="scss">
.time-box{
	display: flex;
	align-items: center;
	p{
		&:nth-child(2){
			padding-left: 30px;
		}
		&:nth-child(3){
			width: 80px;
			font-size: 12px;
			color: #999999;
			border: 1px solid #ddd;
			border-radius: 5px;
			padding: 5px 10px;
		}
	}
}
.time-box2{
	display: flex;
	align-items: center;
	p{
		margin: 0;
		&:nth-child(3){
			padding-left: 30px;
		}
		&:nth-child(2),
		&:nth-child(4){
			width: 80px;
			font-size: 12px;
			color: #999999;
			border: 1px solid #ddd;
			border-radius: 5px;
			padding: 5px 10px;
		}
	}
}
.condition-box{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 5px 0 10px 0;
	.flx1{
		flex:1;
	}
	.state-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		p{
			margin: 0 10px 0 20px;
		}
	}
	.btn{
		margin-left: 20px;
		margin-bottom: 5px;
	}
}
.ttable{
	margin-top:0;	
	.txt{
		display: flex;
		align-items: center;
		.red{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: red;
			margin-right: 5px;
		}
	}
	.days{
		position: absolute;
		top:5px;
		left:5px;
		right:5px;
		bottom:5px;
	}
	.ico{
		color:#407cff;
		&~.tinp{
			right:50px;
		}
		&.ar{
			position: absolute;
			top:5px;
			right:17px;
			bottom:5px;
			margin: auto;
			width:14px;
			height:14px;
			z-index: 1;
		}
	}
	.tinp{
		// position: absolute;
		// top:50px;
		// left:5px;
		// right:5px;
		// bottom:50px;
		// width:auto;
		// display: flex;
		// align-items: center;
		// background:#fff;
		background-color: transparent;
	}
}
.documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.caozuo{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
		margin-left: 5px;
	}
}
.stop-box{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 40px;
	.sbox{
		font-size: 30px;
		color: #ccc;
	}
}

.input-select{
	margin:0 -10px;
}
.level-box{
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: #fff;
	border: 2px solid;
}
.level-red{
	color: #e41111;
	border-color: #e41111;
}
.level-orange{
	color: #e3940b;
	border-color: #e3940b;
}
.level-green{
	color: #0b9c1f;
	border-color: #0b9c1f;
}
.tpdf{
	display: flex;
	.mens{
		flex:1;
		.span{
			display: block;
		}
	}
}

</style>